//
// components.scss
//

/*********************************/
/*         Components            */
/*===============================*/
//Buttons
@each $name,
$value in $colors {
    .btn-#{$name} {
        background-color: #{$value} !important;
        border: 1px solid #{$value} !important;
        color: $white !important;
        box-shadow: 0 3px 5px 0 rgba($value, 0.1);
        &:hover, &:focus, &:active, &.active, &.focus{
            background-color: darken($value, 10%) !important;
            border-color: darken($value, 10%) !important;
            color: $white !important;
        }
    }

    .btn-soft-#{$name} {
        background-color: rgba($value, 0.1) !important;
        border: 1px solid rgba($value, 0.1) !important;
        color: #{$value} !important;
        box-shadow: 0 3px 5px 0 rgba($value, 0.1);
        &:hover, &:focus, &:active, &.active, &.focus{
            background-color: #{$value} !important;
            border-color: #{$value} !important;
            color: $white !important;
        }
    }

    .btn-outline-#{$name} {
        border: 1px solid #{$value};
        color: #{$value};
        background-color: transparent;    
        &:hover, &:focus, &:active, &.active, &.focus, &:not(:disabled):not(.disabled):active{
            background-color: #{$value};
            border-color: #{$value};
            color: $white !important;
            box-shadow: 0 3px 5px 0 rgba($value, 0.1);
        }
    }

    .btn-link {
        &.#{$name} {
            padding: 0 !important;
            color: $dark;
            position: relative;
            &:hover, &:focus, &:active, &.active, &.focus, &:not(:disabled):not(.disabled):active{
                color: $value !important;
            }
            &:after {
                background-color: $value !important;
            }
        }
    }
}
.btn {
    padding: 6px 20px;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.5px;
    transition: all 0.3s;
    border-radius: 6px;
    height: 40px;
    &:focus {
        box-shadow: none !important;
    }
    &.btn-sm {
        padding: 7px 16px;
        font-size: 10px;
        height: auto;
    }
    &.btn-lg {
        padding: 14px 30px;
        font-size: 16px;
        height: auto;
    } 
    &.searchbtn {
        padding: 6px 20px;
    }
    &.btn-pills {
        border-radius: 30px;
    }
    &.btn-light {
        color: $dark !important;
        border: 1px solid $gray-300 !important;
        &:hover, &:focus, &:active, &.active, &.focus{
            background-color: darken($light, 10%) !important;
            color: $dark !important; 
        }
    }  
    &.btn-soft-light {
        color: rgba($dark, 0.5) !important;
        border: 1px solid $gray-300 !important;
        &:hover, &:focus, &:active, &.active, &.focus{
            color: $dark !important; 
        }
    }    
    &.btn-outline-light {
        border: 1px solid $gray-300 !important;
        color: $dark !important;
        background-color: transparent;    
        &:hover, &:focus, &:active, &.active, &.focus{
            background-color: $light !important;
        }
    }
    &.btn-link {
        font-weight: 400;
        &:after {
            content: "";
            position: absolute;
            height: 1px;
            width: 0;
            right: 0;
            left: 0;
            bottom: 0;
            transition: all 0.5s ease;
        }
        &.active,
        &:hover {
            &:after {
                width: 100%;
                right: auto;
                left: 0;
                height: 1px;
            }
        }
    }
    &.btn-icon {
        height: 36px;
        width: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        .icons {
            height: 16px;
            width: 16px;
            font-size: 16px;
        }
        &.btn-lg {
            height: 48px;
            width: 48px;
            .icons {
                height: 20px;
                width: 20px;
                font-size: 20px;
            }
        }
        &.btn-sm {
            height: 30px;
            width: 30px;
        }
    }
}

button:not(:disabled) {
    outline: none;
}


//Shadow 
.shadow {
    box-shadow: $shadow !important;
}
.shadow-lg {
    box-shadow: $shadow-lg !important;
}
.shadow-md {
    box-shadow: $shadow-md !important;
}

//Avatar
.avatar {
    &.avatar-coin {
        height: 36px;
        width: 36px;
    }
    &.avatar-ex-sm {
        max-height: 25px;
    }
    &.avatar-ex-small {
        height: 36px;
        width: 36px;
    }
    &.avatar-md-sm {
        height: 45px;
        width: 45px;
    }
    &.avatar-small {
        height: 65px;
        width: 65px;
    }
    &.avatar-md-md {
        height: 80px;
        width: 80px;
    }
    &.avatar-medium {
        height: 110px;
        width: 110px;
    }
    &.avatar-large {
        height: 140px;
        width: 140px;
    }
    &.avatar-xl-large {
        height: 180px;
        width: 180px;
    }
    &.avatar-ex-large {
        height: 200px;
        width: 200px;
    }
    &.avatar-250 {
        height: 250px;
        width: 250px;
    }
}

//Form
.form-label,
.form-check-label {
    font-size: 14px;
}

.form-control {
    border: 1px solid $gray-300;
    font-size: 14px;
    line-height: 26px;
    background-color: $white;
    border-radius: 6px;
    &:focus {
        border-color: $primary;
        box-shadow: none;
    }
    &[readonly] {
        background-color: $white;
    }
    &:disabled {
        background-color: $gray-300;
    }
}


.form-check-input {
    border: 1px solid $gray-300;
    &:focus {
        border-color: $primary;
        box-shadow: none;
    }
    &.form-check-input:checked {
        background-color: $primary;
        border-color: $primary;
    }
}


//Processbar
.progress-box {
    .progress {
        height: 8px;
        overflow: visible;
        .progress-bar {
            animation: animate-positive 3s !important;
            overflow: visible !important;
            border-radius: 5px;
        }
        .progress-value {
            position: absolute;
            top: -30px;
            right: -15px;
            font-size: 13px;
        }
    }
    @keyframes animate-positive {
        0% {
            width: 0;
        }
    }
}

//Subscribe form
.subcribe-form {
    .btn {
        padding: 10px 20px;
        height: auto;
    }
    input {
        padding: 12px 20px;
        width: 100%;
        color: $dark !important;
        border: none;
        outline: none !important;
        padding-right: 160px;
        padding-left: 30px;
        background-color: rgba($white, 0.8);
        height: 50px;
    }
    button {
        position: absolute;
        top: 2px;
        right: 3px;
        outline: none !important;
    }
    form {
        position: relative;
        max-width: 600px;
        margin: 0px auto;
    }
}

//Map
.map {
    line-height: 0;
    &.map-gray {
        filter: grayscale(100%);
        iframe {
            height: 100vh;
        }
    }
    iframe {
        width: 100%;
        height: 500px;
    }
    &.map-100vh {
        iframe {
            height: 100vh;
        }
    }
}

// Loader
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, $white, $white);
    z-index: 9999999;
    #status {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        .spinner {
            width: 40px;
            height: 40px;
            position: relative;
            margin: 100px auto;
            .double-bounce1, .double-bounce2 {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: $primary;
                opacity: 0.6;
                position: absolute;
                top: 0;
                left: 0;
                animation: sk-bounce 2.0s infinite ease-in-out;
            }
            .double-bounce2 {
                animation-delay: -1.0s;
            }
        }
    }
}

@keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
    }
}

//Social icons
.social-icon {
    li {
        a {
            color: $dark;
            border: 1px solid $dark;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            width: 32px;
            transition: all 0.4s ease;
            overflow: hidden;
            position: relative;
            .fea-social {
                stroke-width: 2;
            }
            &:hover {
                background-color: $primary;
                border-color: $primary !important;
                color: $white !important;
                .fea-social {
                    fill: $primary;
                }
            }
        }
    }

    &.tagline-social {
        li {
            a {
                border: none;
                height: auto;
                width: auto;
                padding-left: 8px;
                i {
                    color: $muted;
                    &:hover {
                        color: $primary !important;
                    }
                }
                &:hover {
                    background: transparent;
                }
            }
        }
        &.social-white {
            li {
                a {
                    i {
                        color: $white;
                    }
                }
            }
        }
        &.social-dark {
            li {
                a {
                    i {
                        color: $dark;
                    }
                }
            }
        }
    }

    &.social {
        li {
            a {
                color: $gray-500;
                border-color: $gray-500;
            }
        }
    }

    &.foot-social-icon {
        li {
            a {
                color: $gray-500;
                border-color: lighten($footer, 5%);
            }
        }
    }
}

//back to top
.back-to-top {
    position: fixed;
    z-index: 99;
    bottom: 30px;
    right: 30px;
    display: none;
    transition: all 0.5s ease;
    height: 36px;
    width: 36px;
    line-height: 34px;
    text-align: center;
    background: $primary;
    color: $white !important;
    .icons {
        transition: all 0.5s ease;
    }
}

//back-to-home
.back-to-home {
    position: fixed;
    top: 4%;
    right: 2%;
    z-index: 1;
}

//Feather Icon
.fea {
    stroke-width: 1.8;
    &.icon-sm {
        height: 16px;
        width: 16px;
    }
    &.icon-ex-md {
        height: 20px;
        width: 20px;
    }
    &.icon-m-md {
        height: 28px;
        width: 28px;
    }
    &.icon-md {
        height: 35px;
        width: 35px;
    }
    &.icon-lg {
        height: 42px;
        width: 42px;
        stroke-width: 1;
    }
    &.icon-ex-lg {
        height: 52px;
        width: 52px;
    }
}

[class^=uil-], 
[class*=" uil-"] {
    &:before {
        margin: 0;
    }
}

.uim-svg {
    vertical-align: 0 !important;
}
